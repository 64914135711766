import React, { useEffect, useRef, useState } from 'react'
import { SuccessModal, PaymentModal, ErrorModal } from '../../components/Modal'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import api, { customFuncsApi } from '../../api'
import { AiOutlineSend } from 'react-icons/ai'
import { isEmpty } from 'lodash/lang'
import logo from '../../assets/images/logo.png'
import { roundCurrency } from '../../utils'
import moment from 'moment-timezone'
import CloseIcon from '../../assets/icons/close'
import ClipLoader from 'react-spinners/ClipLoader'
import html2PDF from 'jspdf-html2canvas/index'
import formatPhoneNumber from '../../helpers/PhoneWithSpaces'
import { Empty, Button as AntdBtn, Tag } from 'antd'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import UpdateUser from '../ViewItem/UpdateUser'
import { useTranslation } from 'react-i18next'
import { IoIosPrint, IoMdDownload } from 'react-icons/io'
import qr from '../../assets/images/QRCode.png'

const ViewRecoveryItem = () => {
	const { getMe } = useSelector((state) => state.main)

	const navigate = useNavigate()
	const location = useLocation()
	const { t } = useTranslation()

	const successRef = useRef()
	const errorRef = useRef()
	const paymentRef = useRef()
	const commentRef = useRef(null)
	const updateUserRef = useRef()

	const query = new URLSearchParams(location.search)
	const DocEntry = JSON.parse(query.get('DocEntry'))
	const InstlmntID = JSON.parse(query.get('InstlmntID'))

	const [commentsLoading, setCommentsLoading] = useState(false)
	const [comment, setComment] = useState('')
	const [subStatus, setSubStatus] = useState('')
	const [daftarbet, setDaftarbet] = useState('')
	const [editDaftar, setEditDaftar] = useState(false)

	const [editComment, setEditComment] = useState(false)
	const [comment2, setComment2] = useState('')

	const [comments, setComments] = useState([])
	const [employeeID, setEmployeeID] = useState('')
	const [dollarLoading, setDollarLoading] = useState(false)
	const [uScore, setUScore] = useState('')
	const [UDueDate, setUDueDate] = useState('')
	const [isCommitLoading, setIsCommitLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState({})
	const [isComment, setIsComment] = useState({
		loading: false,
		order: -1,
	})
	const [dollar, setDollar] = useState(1)
	// const [deviceInfoData, setDeviceInfoData] = useState([])
	const [isLoading2, setIsLoading2] = useState(false)
	const [sumPay, setSumPay] = useState(0)
	const [payingSum, setPayingSum] = useState(0)
	const [tableData, setTableData] = useState([])
	const [tableInvoicePayments, setTableInvoicePayments] = useState([])
	const [tableData2, setTableData2] = useState([])
	const successModalRef = useRef()
	const [userFields, setUserFields] = useState([])
	const [employeeInfo, setEmployeeInfo] = useState([])
	const [problems, setProblems] = useState([])
	const [phoneNum, setPhoneNum] = useState('')
	const [executer, setExecuter] = useState([])
	const [firstPay, setFirstPay] = useState('')
	const [totalDebt, setTotalDebt] = useState('')
	const [paidToDate, setPaidToDate] = useState('')
	const [docTotal, setDocTotal] = useState('')

	const role =
		getMe.Department2.Name === 'Boshqaruv' ||
		getMe.Department2.Name === 'Undiruvchi1' ||
		getMe.Department2.Name === 'Undiruv_kocha'
	useEffect(() => {
		if (commentRef.current) {
			commentRef.current.scrollTop = commentRef.current.scrollHeight
		}
	}, [comments])

	useEffect(() => {
		getAllData()
		getComments()
		getInformationOfItem()
		getInvoicePayments()
		userFieldsFn()
		getEmployee()
		getProblem()
		getAllGetterMoney()
	}, [])

	useEffect(() => {
		if (get(data, 'CardCode', '') !== null) {
			getBp()
		}
	}, [get(data, 'CardCode', '')])

	const userFieldsFn = () => {
		api
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq '@PROBLEM'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}
	const getProblem = () => {
		api.get('U_PROBLEM').then((res) => {
			setProblems(JSON.parse(res.data).value)
		})
	}

	const getInformationOfItem = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInstallments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=50',
				},
			})
			.then((res) => {
				const resData = res.data.value || []
				setTableData2(resData)
				setTableData(resData)
				setIsLoading(false)
				let a = JSON.parse(JSON.stringify(resData))
				let allSum = 0
				let paySum = 0
				const now = moment().format('YYYYMMDD')
				for (let i = 0; i < a.length; i++) {
					if (!moment(a[i].DueDate, 'YYYYMMDD').isAfter(now, 'day')) {
						allSum = allSum + a[i].PaidToDate
						paySum = paySum + a[i].InsTotal
					}
				}
				setSumPay(allSum)
				setPayingSum(paySum)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}
	const getInvoicePayments = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInvoiceIncomingPayments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setTableInvoicePayments(res.data.value)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getEmployee = () => {
		// ('${get(data, 'SlpCode', 0)}')?$select=FirstName,LastName
		api
			.get(`EmployeesInfo`, {
				params: {
					$filter: `FirstName eq 'Undiruvchi' or LastName eq 'Undiruvchi'`,
				},
			})
			.then((res) => {
				setEmployeeInfo(JSON.parse(res.data))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getAllData = async () => {
		await customFuncsApi
			.get(`installment?DocEntry=${DocEntry}&InstlmntID=${InstlmntID}`)
			.then((res) => {
				setData(get(res, 'data', {}))
				setEmployeeID(get(res, 'data.U_Dunner', ''))
				setSubStatus(get(res, 'data.U_Substatus', ''))
				setUScore(get(res, 'data.U_Score', ''))
				setUDueDate(
					get(res, 'data.U_PromisedDate')
						? moment(get(res, 'data.U_PromisedDate')).format('YYYY-MM-DD')
						: '',
				)
				setDaftarbet(get(res, 'data.U_DaftarBet', ''))
				setComment2(get(res, 'data.U_COMMENT', ''))
				setFirstPay(get(res.data, 'U_FirstPayment', ''))
				setTotalDebt(get(res.data, 'TotalDebt', 0))
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					navigate('/login', { replace: true })
				}
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getComments = () => {
		setCommentsLoading(true)
		api
			.get(
				`U_COMMENTS?$filter=U_Invoice eq ${DocEntry} and U_InstlmntID eq ${InstlmntID}&$orderby=U_CreateTime asc, Code desc`,
			)
			.then((res) => {
				setComments(get(JSON.parse(res.data), 'value', []))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setCommentsLoading(false)
			})
	}

	const deleteComments = (id, order) => {
		setIsComment((prevState) => ({ ...prevState, loading: true, order }))
		api
			.delete(`U_COMMENTS(${id})`)
			.then((res) => getComments())
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally((res) => {
				setIsComment((prevState) => ({
					...prevState,
					loading: false,
					order: -1,
				}))
			})
	}

	const sendComment = () => {
		setComment('')
		const date = moment().tz('Etc/GMT-5').format('YYYY-MM-DDTHH:mm:ssZ')

		const body = {
			U_Dunner: get(getMe, 'EmployeeID', ''),
			U_CreateDate: date,
			U_Invoice: get(data, 'DocEntry', ''),
			U_InstlmntID: InstlmntID,
			U_Text: comment,
			U_CreateTime: date,
			Name: `${get(getMe, 'FirstName', '')} ${get(getMe, 'LastName', '')}`,
		}
		api
			.post('U_COMMENTS', body)
			.then((res) => getComments())
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getUSD = () => {
		setDollarLoading(true)
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: 'UZS',
				Date: moment(),
			})
			.then((res) => {
				setDollar(JSON.parse(res.data))
			})
			.catch((err) => {
				setDollar('error')
			})
			.finally(() => setDollarLoading(false))
	}
	useEffect(() => {
		getUSD()
	}, [])
	const createPayment = ({
		DocDate,
		CurrencyRate,
		AmountUsd,
		AmountUzs,
		AmountPayme,
		NumberCard,
		Terminal,
		smsSend,
		AmountUsdCard,
		SecurityName,
		Time,
	}) => {
		setIsLoading(true)
		const payments = []
		if (AmountUsd) {
			payments.push({
				usdSum: AmountUsd,
				account: getMe.U_CashAccount,
				currency: 'USD',
				uzsSum: null,
			})
		}
		if (AmountUzs) {
			payments.push({
				usdSum: AmountUzs / CurrencyRate,
				account: getMe.U_CashUzsAccount,
				currency: 'UZS',
				uzsSum: AmountUzs,
			})
		}
		if (AmountPayme) {
			payments.push({
				usdSum: AmountPayme / CurrencyRate,
				account: NumberCard || getMe.U_CardAccount,
				currency: 'UZS',
				uzsSum: AmountPayme,
			})
		}
		if (Terminal) {
			payments.push({
				usdSum: Terminal / CurrencyRate,
				account: getMe.U_TerminalAccount,
				currency: 'UZS',
				uzsSum: Terminal,
			})
		}
		if (AmountUsdCard) {
			payments.push({
				usdSum: AmountUsdCard,
				account: getMe.U_CardUsdAccount,
				currency: 'USD',
				uzsSum: null,
			})
		}
		customFuncsApi
			.post('addIncomingPayments', {
				DocDate,
				DocEntry: DocEntry,
				U_Time: Time,
				CardCode: get(data, 'CardCode', null),
				DocRate: CurrencyRate,
				shouldSendSms: smsSend,
				U_CashFlow: '1',
				payments: payments,
				'Security.U_Status': SecurityName,
			})
			.then((err) => {
				paymentRef.current?.close()
				successModalRef.current?.open('Платеж успешно произведен!')
				getInformationOfItem()
				getInvoicePayments()
				getAllData()
			})
			.catch((err) => {
				paymentRef.current?.close()
				errorRef.current?.open(err.response.data)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const cancel = () => {
		navigate('/recovery')
	}

	const allSaves = () => {
		setIsCommitLoading(true)
		const updateData = {
			U_Score: uScore,
			U_COMMENT: comment2,
			U_DaftarBet: daftarbet,

			U_Substatus:
				subStatus && typeof subStatus === 'string'
					? subStatus.includes(',')
						? subStatus.split(',')[0]
						: subStatus
					: '',
			DocumentInstallments: [
				{
					InstallmentId: get(data, 'InstlmntID', 0),
					U_Comment: comment,
					U_Employee: employeeID > 0 ? employeeID : null,
					U_PromisedDate: `${UDueDate}T00:00:00.000Z`,
				},
			],
		}

		if (Number(uScore) > 1) {
			updateData.U_Dunner = employeeID
		}

		api
			.patch(`Invoices(${get(data, 'DocEntry', 0)})`, updateData)
			.then(() => {
				successRef.current?.open('Заказ успешно сохранен')
				setIsCommitLoading(false)
			})
			.catch((err) => {
				setIsCommitLoading(false)
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const formatPhoneNumber2 = (phoneNum) => {
		// Split the phoneNum by ',' and '"' and remove empty entries
		const parts = phoneNum.split(/[,]/).filter(Boolean)

		return parts.slice(0, 2).join(', ')
	}

	const downloadCheck = (id, data) => {
		let element = document.getElementById('check' + id)
		element.style.display = 'block'

		element.style.width = '210mm'
		element.style.height = '297mm'

		html2PDF(element, {
			margin: 0,
			filename: 'myfile.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: {
				scale: 2,
				logging: true,
				dpi: 150,
				letterRendering: true,
			},
			jsPDF: {
				unit: 'mm',
				format: 'a4',
				orientation: 'portrait',
			},
		}).then(() => {
			element.style.display = 'none'
		})
	}

	const navigateToPrint = (id, v, data) => {
		const totalInUZS = (
			Number(v.CashSum).toFixed(2) * Number(v.DocRate)
		).toFixed(2) // Ensure it's formatted correctly
		const formattedData = {
			InstId: v.visualInstNum,
			DocDate: v.DocDate,
			totalInUZS: totalInUZS,
			CardName: get(data, 'CardName', '-'),
			phoneNum: phoneNum, // Assuming phoneNum is a variable defined somewhere
			Dscription: get(data, 'Dscription', '-'),
		}
		navigate('/cheque', { state: { id, data: formattedData } })
	}

	const viewItem = (v) => {
		updateUserRef.current?.open(v)
	}

	const getBp = () => {
		const cardCode = get(data, 'CardCode', '')
		if (cardCode) {
			api
				.get(
					`BusinessPartners('${cardCode}')?$select=U_Telephone,CardName,FreeText,U_Comment`,
				)
				.then((res) => {
					const resData = JSON.parse(res.data)
					setPhoneNum(get(resData, 'U_Telephone', ''))
				})
				.catch((err) => {
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				})
		}
	}
	const getAllGetterMoney = () => {
		api
			.get(
				`$crossjoin(EmployeesInfo, Departments)?$filter=(Departments/Name eq 'Undiruv' or Departments/Name eq 'Undiruvchi1' or Departments/Name eq 'Undiruv_kocha') and EmployeesInfo/Department eq Departments/Code&$expand=EmployeesInfo($select=EmployeeID,LastName,FirstName,U_CashAccount,U_CardAccount,U_TransAccount),Departments($select=Name,Code)`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=90000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				if (get(getMe, 'Department2.Name', '') === 'Undiruv') {
					const data = resData.filter(
						(v) => v.EmployeesInfo.EmployeeID === get(getMe, 'EmployeeID', ''),
					)
					setExecuter(data)
				} else setExecuter(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}
	return (
		<Layout>
			{/* <ViewRecoveryItemStyle> */}
			<div className="flex gap-5 flex-col md:flex-row flex-1">
				<div className=" shadow-lg  p-2 w-full  border-[1px] border-[#DFE2E8] overflow-y-scroll flex flex-col 3xl:flex-row gap-5">
					<div className="grid gap-4 grid-cols-2   w-full h-fit p-2">
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('code')}
							</p>
							<input
								type="text"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={get(data, 'DocNum', 'Kod')}
								disabled={true}
							/>
						</div>
						<div className="flex items-center w-full">
							<div className="w-full">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('FIO')}
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'CardName', 'Покупатель')}`}
									disabled={true}
								/>
							</div>
							<div className="w-[50px] mt-6">
								<Button
									onClick={() => {
										viewItem(get(data, 'CardCode', ''))
									}}
									className="w-[50px] h-10"
								>
									<EyeOutlined className="w-[50px]" />
								</Button>
							</div>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('product')}
							</p>
							<input
								type="text"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={get(data, 'Dscription', 'Товар')}
								disabled={true}
							/>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('customer')}
							</p>
							<input
								type="text"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={get(data, 'LastPurchase.CardName', '-')}
								disabled={true}
							/>
						</div>

						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('supplierDate')}
							</p>
							<input
								type="text"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={
									get(data, 'LastPurchase.DocDate', '-') !== null
										? moment(get(data, 'LastPurchase.DocDate', '-')).format(
												'DD-MM-YYYY',
											)
										: '-'
								}
								disabled={true}
							/>
						</div>

						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>Тел.</p>
							<input
								type="text"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={formatPhoneNumber(get(data, 'U_Telephone', 'Tel'))}
								disabled={true}
							/>
						</div>

						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('imei')}
							</p>
							<input
								placeholder="IntrSerial"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={get(data, 'IntrSerial', '-') || ''}
								disabled={true}
							/>
						</div>
						<div>
							{/*<p className={"comment"}>Комментарии</p>*/}
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('comment')}
							</p>
							<div className={'commentSection'}>
								<textarea
									name="comment"
									// id="comment"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									// cols="30"
									rows="1"
									value={comment}
									onChange={(v) => setComment(v.target.value)}
								/>
								<button
									className={'sendComment'}
									onClick={() => sendComment()}
									disabled={isEmpty(comment)}
								>
									<AiOutlineSend />
								</button>
							</div>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('paymnetDate')}
							</p>
							<input
								type="string"
								placeholder={t('price2')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={get(data, 'DueDate', 'Sana').slice(0, 10)}
								disabled={true}
							/>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('purchaseComment')}
							</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={get(data, 'Comments', '-')}
								disabled={true}
							/>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('price2')}
							</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={new Intl.NumberFormat('fr-FR', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})
									.format(get(data, 'DocTotal', '-'))
									.replace(',', '.')}
								disabled={true}
							/>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('currentPrice')}
							</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={new Intl.NumberFormat('fr-FR', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})
									.format(get(data, 'GrossBuyPr', '-'))
									.replace(',', '.')}
								disabled={true}
							/>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('status')}
							</p>
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								onChange={(e) => {
									const val = e.target.value
									setUScore(val)
									const selectedProblem = problems.find(
										(problem) => problem.Code === val,
									)
									if (selectedProblem && selectedProblem.U_Employee !== null) {
										setEmployeeID(selectedProblem.U_Employee)
									}
								}}
								value={uScore}
							>
								<option value={''} key={0} disabled></option>

								{problems
									.slice(
										0,
										get(getMe, 'Department2.Name', '') !== 'Undiruvchi1' &&
											get(getMe, 'Department2.Name', '') !== 'Boshqaruv' &&
											get(getMe, 'Department2.Name', '') !== 'Undiruv_kocha'
											? 2
											: problems.length,
									)
									.map((value) => (
										<option value={value.Code} key={value.Code}>
											{value.Name}
										</option>
									))}
							</select>
						</div>
						{get(getMe, 'Department2.Name', '') !== 'Undiruvchi1' &&
						get(getMe, 'Department2.Name', '') !== 'Boshqaruv' &&
						get(getMe, 'Department2.Name', '') !== 'Undiruv_kocha' ? null : (
							<>
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('subStatus')}
									</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										onChange={(event) => setSubStatus(event.target.value)}
										value={subStatus}
										// disabled={
										// 	!String(uScore.split(',')[1])
										// 		.toLowerCase()
										// 		.includes('problem')
										// }
										disabled={!role}
									>
										><option value={''} disabled></option>
										{userFields
											?.find((item) => item.Name === 'SubStatus')
											?.ValidValuesMD.map((value) => (
												<option
													key={value.Value}
													value={`${value.Value},${value.Description}`}
												>
													{value.Description}
												</option>
											))}
									</select>
								</div>
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Сменить приёмщик
									</p>
									<select
										name="executer"
										id="executer"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										onChange={(v) => {
											setEmployeeID(v.target.value)
										}}
										value={employeeID}
										disabled={
											!role ||
											Number(uScore) === 2 ||
											Number(uScore) === 3 ||
											Number(uScore) === 4 ||
											Number(uScore) === 5
										}
									>
										{executer.map((v, i) => {
											return (
												<option
													value={get(v, 'EmployeesInfo.EmployeeID', 0)}
													key={i}
													selected={
														v.EmployeesInfo.EmployeeID ===
														get(data, 'U_Dunner', '')
													}
												>
													{get(v, 'EmployeesInfo.FirstName', 'Name')}{' '}
													{get(v, 'EmployeesInfo.LastName', 'Surename')}
												</option>
											)
										})}
									</select>
								</div>
							</>
						)}

						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('amountMonth')}
							</p>
							<input
								type="number"
								placeholder={t('price2')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={get(data, 'Installmnt', 1) - 1}
								disabled={true}
							/>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('firstPayingMonth')}
							</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={new Intl.NumberFormat('fr-FR', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})
									.format(firstPay)
									.replace(',', '.')}
								disabled={true}
							/>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('currentMonth')}
							</p>
							<input
								type="number"
								placeholder={t('price2')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={roundCurrency(get(data, 'InstlmntID', 1)) - 1}
								disabled={true}
							/>
						</div>

						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('paymnetTerm')}
							</p>
							<input
								type="date"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								onChange={(e) => {
									setUDueDate(e.target.value)
								}}
								value={UDueDate}
								defaultValue={UDueDate}
							/>
						</div>
						<div></div>
						<div className="w-full">
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('commentInventory')}
							</p>
							<div className="flex items-center gap-2 w-full">
								<textarea
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={comment2}
									disabled={!editComment}
									onChange={(e) => {
										setComment2(e.target.value)
									}}
								/>
							</div>
						</div>
						<div className="w-[20px] mt-10">
							<AntdBtn
								onClick={() => {
									setEditComment(!editComment)
								}}
								className="bg-[#0A4D68] text-white  h-10"
							>
								{!editComment ? (
									<EyeOutlined className="w-[20px] text-xl" />
								) : (
									<EyeInvisibleOutlined className="w-[20px] text-xl" />
								)}
							</AntdBtn>
						</div>
						<div className="w-full">
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('pageNumber')}
							</p>
							<div className="flex items-center gap-2 w-full">
								<textarea
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={daftarbet}
									disabled={!editDaftar}
									onChange={(e) => {
										setDaftarbet(e.target.value)
									}}
								/>
							</div>
						</div>
						<div className="w-[20px] mt-10">
							<AntdBtn
								onClick={() => {
									setEditDaftar(!editDaftar)
								}}
								className="bg-[#0A4D68] text-white  h-10"
							>
								{!editDaftar ? (
									<EyeOutlined className="w-[20px] text-xl" />
								) : (
									<EyeInvisibleOutlined className="w-[20px] text-xl" />
								)}
							</AntdBtn>
						</div>

						<div className="flex gap-3 flex-col lg:flex-row justify-end w-full col-span-2 2xl:col-span-1">
							<div className="flex gap-3">
								<Button btnStyle={{ backgroundColor: 'red' }} onClick={cancel}>
									{t('back')}
								</Button>
							</div>
							<Button
								btnStyle={{ width: 250 }}
								hoverBtnStyle={{ width: 250 }}
								onClick={allSaves}
								isLoading={isCommitLoading}
							>
								{t('save')}
							</Button>
						</div>
					</div>

					<div className="flex items-center justify-end py-5">
						{' '}
						<div className="flex flex-col items-center p-5 justify-center sm:flex-row gap-5 sm:gap-5 rounded-3xl bg-white drop-shadow-xl">
							<div className="flex gap-2 flex-col items-center">
								<Tag color="green" className=" text-sm">
									{t('course')}
								</Tag>
								<span className="font-bold text-zinc-900">
									{' '}
									{new Intl.NumberFormat('fr-FR')
										.format(dollar)
										.replace(',', '.')}{' '}
									UZS
								</span>
							</div>
							<div className="flex gap-2 flex-col items-center">
								<Tag color="red" className=" text-sm">
									{t('debt')}
								</Tag>
								<span className="font-bold text-zinc-900">
									{new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})
										.format(payingSum - sumPay)
										.replace(',', '.')}{' '}
									USD
								</span>
							</div>
							<div className="flex gap-2 flex-col items-center">
								<Tag color="red" className=" text-sm">
									{t('debtTotal')}
								</Tag>
								<span className="font-bold text-zinc-900">
									{new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})
										.format(Math.floor(totalDebt * 100) / 100)
										.replace(',', '.')}{' '}
									USD
								</span>
							</div>

							{getMe?.Department2.Name !== 'Scoring' ? (
								<div className="flex items-center gap-5">
									{' '}
									<Button
										btnStyle={{ backgroundColor: '#243AB5' }}
										onClick={() => paymentRef.current?.open()}
										isLoading={isLoading || isLoading2 || dollarLoading}
										hoverBtnStyle={{ backgroundColor: '#243AB5' }}
									>
										{t('pay')}
									</Button>
								</div>
							) : (
								''
							)}
						</div>
					</div>
					<div className="flex flex-col sm:flex-row  gap-7 p-5">
						<div>
							<h1 className={'font-bold mb-3'}>{t('paymentPlan')}</h1>

							<table className="w-full text-sm text-left rtl:text-right text-black">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											N
										</th>
										<th scope="col" className="px-6 py-4">
											{t('date')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('payment')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('paid')}
										</th>
										{/* <th scope="col" className="px-6 py-4">
												Действие
											</th> */}
									</tr>
								</thead>
								<tbody>
									{tableData.length > 0 ? (
										tableData.map((v, i) => {
											let a = v.DueDate.split('')
											a.splice(4, 0, '-')
											a.splice(7, 0, '-')
											a.join()
											return (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{v?.visualInstNum}
													</td>
													<td className="px-6 py-4 whitespace-nowrap">
														{' '}
														{moment(v.DueDate).format('DD-MM-YYYY')}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(Number(v.InsTotal))
															.replace(',', '.')}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(Number(v.PaidToDate))
															.replace(',', '.')}
													</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<div className="overflow-y-auto">
							<h1 className={'font-bold mb-3'}>{t('fact-invoice')}</h1>
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											N
										</th>
										<th scope="col" className="px-6 py-4">
											{t('date')}
										</th>
										{/*<th scope="col" className="px-6 py-4">*/}
										{/*	Оплата*/}
										{/*</th>*/}
										<th scope="col" className="px-6 py-4">
											{t('paid')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('course')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('acctName')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('comment')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('action')}
										</th>
									</tr>
								</thead>
								<tbody>
									{tableInvoicePayments.length > 0 ? (
										tableInvoicePayments.map((v, i) => {
											const totalInUZS =
												Number(v.CashSum).toFixed(2) * Number(v.DocRate)
											return (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
												>
													<td className="px-8 py-6 font-medium text-gray-900 ">
														{' '}
														{Number(v.U_CashFlow) === 4 ||
														Number(v.U_CashFlow) === 5
															? v['U_CashFlow.Descr']
															: v?.visualInstNum}
													</td>

													<td className="px-6 py-4 whitespace-nowrap">
														{moment(v.DocDate).format('DD-MM-YYYY')}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(Number(v.CashSum))
															.replace(',', '.')}{' '}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(Number(v.DocRate))
															.replace(',', '.')}{' '}
													</td>

													<td className="px-6 py-4 ">{v.AcctName}</td>
													<td className="px-8 py-6 font-medium text-gray-900 ">
														{' '}
														{Number(v.U_CashFlow) === 4 ||
														Number(v.U_CashFlow) === 5
															? v.Comments
															: '-'}
													</td>
													<td className="px-6 py-4">
														<div className="flex items-center gap-2">
															<button
																className={
																	'whitespace-nowrap bg-[#0A4D68] text-white p-2 rounded-md disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 hover:bg-[#083D55] hover:text-white'
																}
																onClick={() => downloadCheck(i, v)}
																disabled={
																	isLoading || isLoading2 || dollarLoading
																}
															>
																<IoMdDownload />
															</button>
															<button
																className={
																	'whitespace-nowrap bg-[#0A4D68] text-white p-2 rounded-md disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 hover:bg-[#083D55] hover:text-white'
																}
																onClick={() => navigateToPrint(i, v, data)} // Pass 'i', 'v', and 'data' here
																disabled={
																	isLoading || isLoading2 || dollarLoading
																}
															>
																<IoIosPrint />
															</button>
														</div>
													</td>
													<div
														id={'check' + i}
														className={
															'hidden'
															// 'absolute -top-[100%] -right-[100%] w-[210mm]'
														}
													>
														<div className="w-[219px] p-1  border border-gray-300 rounded-md shadow-md">
															<p className={'mb-2 text-center'}>
																***************************
															</p>

															<div className="textAlign-center  mb-2 topCard">
																<img
																	src={logo}
																	className="w-[150px]"
																	alt={'check list in credo mobile'}
																/>
															</div>

															<p className="topTitle">
																{t('Квитанция платежа кредита')}
															</p>
															<div className="border-t border-black mb-2 card"></div>

															<div className="betweenCard2">
																<p className="title">ID номер:</p>
																<p className="title ">{v.InstId}</p>
															</div>

															<div className="betweenCard2">
																<p className="title">Дата платежа:</p>
																<p className="title">
																	{moment(v.DocDate).format('DD/MM/YYYY')}
																</p>
															</div>

															<div className="betweenCard2">
																<p className="title">Сумма платежа:</p>
																<p className="title">
																	{new Intl.NumberFormat('fr-FR', {
																		minimumFractionDigits: 2,
																		maximumFractionDigits: 2,
																	})
																		.format(totalInUZS)
																		.replace(',', '.')}
																</p>
															</div>
															{/* get(data, 'CardName', 'Покупатель') */}
															<div className="betweenCard2">
																<p className="title">ФИО плательщика:</p>
																<p className="title">
																	{get(data, 'CardName', '-')}
																</p>
															</div>

															<div className="betweenCard2">
																<p className="title">Тел. номер плательщика:</p>
																<p className="title">
																	{formatPhoneNumber(
																		formatPhoneNumber2(phoneNum),
																	)}
																</p>
															</div>
															<div className="betweenCard2">
																<p className="title">
																	Наименование товара: <br /> Mahsulot nomi:
																</p>
																<p className="title">
																	{get(data, 'Dscription', '-')}
																</p>
															</div>

															<div className="betweenCard2">
																<p className="title">Подпис/Imzo:</p>
															</div>

															<p className="title">
																Telegram kanalga ulanish uchun QR kod:
															</p>
															<div className="flex items-center justify-center">
																<img src={qr} alt="" className="w-[250px] " />
															</div>
															<div className="border-t border-black mb-2 card"></div>

															<p className="title">
																Telegram kanali manzili: Credomobile.uz
															</p>
															<div className="border-t border-black mb-2 mt-2 card"></div>

															<p className="title">
																Aloqa uchun telefon: 71-200-30-07
															</p>
															<p className={'mt-2 text-center'}>
																***************************
															</p>
														</div>
													</div>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="shadow-md w-full pt-5  md:w-[28%] border-[1px] border-[#DFE2E8]">
					<div className="p-4 shadow-md">
						{t('comment')}{' '}
						{commentsLoading.loading && (
							<ClipLoader
								loading={true}
								size={10}
								aria-label="Loading Spinner"
								data-testid="loader"
							/>
						)}
					</div>
					<div ref={commentRef} className="p-4 h-[85vh] overflow-y-scroll">
						{comments.map((v, i) => {
							return (
								<div className="mb-4">
									<p className={'font-medium text-zinc-400 text-sm mb-1'}>
										{moment(get(v, 'U_CreateTime', '')).format(
											'DD-MM-YYYY HH:mm',
										)}
										{'  '}
										{get(v, 'Name', '')}
										{isComment.loading && isComment.order === i && (
											<ClipLoader
												loading={true}
												size={10}
												aria-label="Loading Spinner"
												data-testid="loader"
											/>
										)}
									</p>

									<div className="inputCard2">
										<div className="p-3 pt-5 font-medium rounded-lg rounded-bl-none bg-[#29d2f5] text-white relative">
											<button
												className="absolute top-1 right-1 z-10 cursor-pointer"
												onClick={() => deleteComments(get(v, 'Code', ''), i)}
											>
												<CloseIcon className="text-white w-[20px] " />
											</button>
											{get(v, 'U_Text', '')}
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
			{/* </ViewRecoveryItemStyle> */}
			<>
				{dollarLoading ? null : (
					<PaymentModal
						getRef={(r) => (paymentRef.current = r)}
						onConfirm={(form) => createPayment(form)}
						onClose={() => paymentRef.current?.close()}
						loading={isLoading}
						currencyRate={dollar}
						totalDebt={new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})
										.format(Math.floor(totalDebt * 100) / 100)
										.replace(',', '.')}
						security={`${get(data, 'U_Security', '-')}`}
					/>
				)}
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<SuccessModal
					getRef={(r) => {
						successRef.current = r
					}}
				/>
				<UpdateUser
					getRef={(ref) => (updateUserRef.current = ref)}
					// onUpdated={() => search(data.currentPage, data.data)}
				/>
				<SuccessModal
					getRef={(r) => (successModalRef.current = r)}
					onConfirm={() => successModalRef.current?.close()}
				/>
			</>
		</Layout>
	)
}

export default ViewRecoveryItem
